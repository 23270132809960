import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CertificacionesAnalisisFalla = () => {
  return (
    <Layout location="/certificaciones">
      <SEO title="Certificaciones" />
      <h1>CERTIFICACION ACEROS ESTRUCTURALES</h1>
    </Layout>
  )
}

export default CertificacionesAnalisisFalla
